import { Component, BaseComponent, namespace, VariationBase } from '@zento-lib/components';
import { currentStoreView } from '@vue-storefront/core/lib/multistore';
import { IVSFStoreUI, KEY as UIKey } from 'theme/@types/vsf/stores/ui';
import { AppContextStore, KEY as appContextKey } from 'theme/@types/zento/stores/applicationContext';
import { IMyAccountStore, KEY as MyAccountKey } from 'theme/@types/zento/stores/myaccount';
import { IVSFUser, KEY as UserKey } from 'theme/@types/vsf/stores/user';
import { IMyFiltersStore, KEY as MyFiltersKey } from 'theme/@types/zento/stores/myfilters';
import { NavigationStore } from 'theme/stores/navigation/navigation';
import type { TopNavigationData } from 'theme/stores/navigation/types';
import { SearchState } from 'theme/stores/search/search';
import { USPStore } from 'theme/stores/usp/usp';

import type { IHeader } from './Header.d';

const uiStore = namespace<IVSFStoreUI>(UIKey);
const appContextStore = namespace<AppContextStore>(appContextKey);
const myAccountStore = namespace<IMyAccountStore>(MyAccountKey);
const uiUser = namespace<IVSFUser>(UserKey);
const myFiltersStore = namespace<IMyFiltersStore>(MyFiltersKey);

/**
 * Header Variations
 *
 * Header Variations component allowing to insert header items order and position.
 **/
@Component({})
@VariationBase
export class Header extends BaseComponent<IHeader, unknown> {
  protected static V = {
    header1: () => import('./variations/header1/header1').then((m) => m.Header1),
    header2: () => import('./variations/header2/header2').then((m) => m.Header2),
    header3: () => import('./variations/header3/header3').then((m) => m.Header3),
    header4: () => import('./variations/header4/header4').then((m) => m.Header4),
    header5: () => import('./variations/header5/header5').then((m) => m.Header5),
    header6: () => import('./variations/header6/header6').then((m) => m.Header6),
    header7: () => import('./variations/header7/header7').then((m) => m.Header7),
    header8: () => import('./variations/header8/header8').then((m) => m.Header8),
    header9: () => import('./variations/header9/header9').then((m) => m.Header9),
    header10: () => import('./variations/header10/header10').then((m) => m.Header10),
  };

  protected static T = {
    labelAccount: 'header_myaccount_label',
    labelCart: 'mycart_label',
    labelWishlist: 'wishlist_label',
    myAccountLogout: 'header_button_myaccount_logout',
    myAccountTitle: 'my_account_title',
    myAccountUserName: 'my_account_user_name',
    searchPlaceholder: 'header_search_input',
    searchButton: 'header_search_button',
    searchProducts: 'header_search_products',
    searchSeeMore: 'header_search_more_products',
    searchCancel: 'header_search_cancel',
    searchLabel: 'header_search_placeholder',
    searchClear: 'header_search_clear',
  };

  @uiStore.State('sidebar')
  protected isSidebarOpen: IVSFStoreUI['state']['sidebar'];

  @uiStore.State('microcart')
  protected isMicrocartOpen: IVSFStoreUI['state']['microcart'];

  @uiStore.State('wishlist')
  protected isWishlistOpen: IVSFStoreUI['state']['wishlist'];

  @uiStore.State('overlay')
  protected isOverlayActive: IVSFStoreUI['state']['overlay'];

  @appContextStore.Getter('isServer')
  protected isServer: boolean;

  @appContextStore.Getter('isMobile')
  protected isMobile: boolean;

  @appContextStore.Getter('isDesktop')
  protected isDesktop: boolean;

  @myAccountStore.State('myaccount')
  protected isMyAccountOpen: IMyAccountStore['state']['myaccount'];

  @myAccountStore.State('modalActive')
  protected isModalActive: IMyAccountStore['state']['modalActive'];

  @myAccountStore.State('drawerActive')
  protected isDrawerActive: IMyAccountStore['state']['drawerActive'];

  @myFiltersStore.State('drawerFilter')
  protected isDrawerFilter: IMyFiltersStore['state']['drawerFilter'];

  @uiUser.State('current')
  protected currentUser: IVSFUser['state']['current'];

  protected navigationStore = new NavigationStore();
  protected uspStore = new USPStore();
  protected activeSearch = new SearchState();

  private isVariationRoot = false;
  private static navigationCache: Record<number, TopNavigationData> = {};

  /**
   * Fetch categories/usp on server side
   */
  async serverPrefetch() {
    return await this.navigationStore.runInParallel(
      this.navigationStore.fetchTopNavigationData(),
      this.fetchSecondaryNavigation(),
      this.fetchTopUsp(),
    );
  }

  created() {
    this.isVariationRoot = BaseComponent.instanceOf(this, Header);
  }

  async beforeMount() {
    this.onBroadcast('store-view-changed', async (storeId) => {
      await this.fetchTopNavigationInfo(storeId);
    });
  }

  async mounted() {
    if (this.isVariationRoot) {
      if (!this.items.length) {
        await this.fetchTopNavigationInfo();
      }

      if (!this.navigationStore.secondaryNavigation.length) {
        await this.fetchSecondaryNavigation();
      }

      if (!this.uspStore.uspsAllPages.length) {
        await this.fetchTopUsp();
      }

      if (this.isDesktop && this.isFeatureProducts.length) {
        await this.featureProduct();
      }
    }
  }

  beforeDestroy() {
    this.offBroadcast('store-view-changed');
  }

  protected get tree() {
    return Header.navigationCache[currentStoreView().storeId] || this.navigationStore.topNavigation;
  }

  protected get items() {
    return Object.keys(this.tree).length > 0 ? this.tree.children_data : [];
  }

  protected get elementsState() {
    return (
      !this.isMicrocartOpen &&
      !this.isWishlistOpen &&
      !this.isMyAccountOpen &&
      !this.isModalActive &&
      !this.isDrawerActive
    );
  }

  protected get secondaryNavigation() {
    return this.navigationStore.secondaryNavigation || [];
  }

  /**
   * Open categories
   */
  protected openSidebarMenu() {
    this.$store.commit('ui/setSidebar', !this.isSidebarOpen);
    this.activeSearch.searchState = false;
  }

  /**
   * Open wishlist drawer
   */
  protected callOpenWishlist() {
    this.$store.dispatch('ui/toggleWishlist');
    this.activeSearch.searchState = false;
  }

  /**
   * Open cart drawer
   */
  protected callOpenCart() {
    this.$store.dispatch('ui/toggleMicrocart');
    this.activeSearch.searchState = false;
  }

  protected get countCartProducts() {
    if (this.extended.$config.zento.cart.countQty) {
      let count = 0;
      this.$store.state.cart.cartItems.forEach((product: Record<string, any>) => (count += product.qty));

      return count;
    } else {
      return this.$store.state.cart.cartItems.length;
    }
  }

  /**
   * Determines feature products
   */
  protected async featureProduct() {
    const featureProducts = this.isFeatureProducts ?? [];
    const products = [];

    if (featureProducts.length) {
      for (const product of featureProducts) {
        const productSingleOptions = {
          sku: product.category_featured_product,
          childSku: product.category_featured_product,
        };

        if ('category_featured_product' in product) {
          await this.$store
            .dispatch('product/featureProduct', {
              options: productSingleOptions,
              setCurrentProduct: false,
              selectDefaultVariant: false,
            })
            .then((resp) => {
              if (resp) {
                products.push(resp);
              }
            })
            .catch((err) => console.error(err));
        }
      }

      this.navigationStore.featureProductItems = products;
    }

    /**
     * Determines feature products ratings
     */
    if (this.extended.$config.zento.theme.category.enableReviews) {
      const reviewItems = this.navigationStore.featureProductItems.map((i) => i.id);

      if (reviewItems) {
        this.$store.dispatch('review/reviewItems', { productIds: reviewItems });
      }
    }
  }

  /**
   * Fetches top usp data
   */
  private async fetchTopUsp() {
    return this.uspStore.fetchUsp();
  }

  /**
   * Fetches menu related data (including categories)
   */
  private async fetchTopNavigationInfo(storeId?: number) {
    if (!Header.navigationCache[storeId]) {
      this.$store.state.category.list = []; // Make sure state list is empty because store has changed and there is no cache
      Header.navigationCache[storeId] = await this.navigationStore.fetchTopNavigationData(storeId);
    }

    return Header.navigationCache[storeId];
  }

  /**
   * Fetches secondary navigation
   */
  private async fetchSecondaryNavigation() {
    return this.navigationStore.fetchSecondaryHeaderData();
  }

  /**
   * Determines if current category has feature product
   */
  private get isFeatureProducts() {
    return this.items.filter(
      (item) =>
        'category_featured_product' in item &&
        (typeof item.category_featured_product === 'string'
          ? item.category_featured_product !== '0'
          : item.category_featured_product !== 0) &&
        (typeof item.category_featured_product === 'string'
          ? item.category_featured_product !== '1'
          : item.category_featured_product !== 1) &&
        item.category_featured_product !== null &&
        item.category_featured_product !== undefined,
    );
  }
}
