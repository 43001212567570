import { BaseStore, Store, Field } from '@zento/lib/stores/BaseStore';

@Store
export class PopUpStore extends BaseStore {
  /**
   * Pop up types
   */
  @Field
  public customPopUp: boolean;

  // TODO: Check why getter doesn't update component
  // public get customPopUp() {
  //   return this.customPopUpData ?? false;
  // }

  // public set customPopUp(enable: boolean) {
  //   this.customPopUpData = enable;
  // }
}
