import { BaseComponent, Component } from '@zento-lib/components';
import { Newsletter } from '@zento/modules/organism/Newsletter/Newsletter';
import { PopUp } from '@zento/modules/organism/PopUp/PopUp';
import { StoreConfig } from 'theme/stores/storeConfig/storeConfig';
import { GDPRStore } from 'theme/stores/gdpr/gdpr';
import { PopUpStore } from 'theme/stores/popUp/popUp';

import style from '../style.scss';

import { Top } from './Top';
import { Bottom } from './Bottom';
import type { IDefaultLayout } from './Default.d';

@Component({})
export class DefaultLayout extends BaseComponent<IDefaultLayout, unknown> {
  private storeConfigData = new StoreConfig();
  private gdprStore = new GDPRStore();
  private popUpStore = new PopUpStore();

  /**
   * Fetches store config data
   */
  async fetchStoreConfigData() {
    return this.storeConfigData.storeConfig();
  }

  /**
   * Fetch store config data on server side
   */
  async serverPrefetch() {
    return await this.fetchStoreConfigData();
  }

  getCookie(name: string) {
    const nameEQ = name + '=';
    const ca = document.cookie.split(';');

    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }

    return null;
  }

  async beforeMount() {
    if (Object.keys(this.storeConfigData.storeConfigurations).length === 0) {
      await this.fetchStoreConfigData();
    }

    if (!this.gdprStore.cookie?.fallback) {
      this.gdprStore.acceptCookies = true;
    }

    const newsletterCookie = this.getCookie('newsletter-pop-up');

    if (newsletterCookie || !this.gdprStore.acceptCookies) {
      this.popUpStore.customPopUp = true;
    }
  }

  public render() {
    return (
      <div class={style.default}>
        <Top key='heading' />
        <section class={style.mainContent}>
          <slot />
        </section>
        <Bottom key='footer' />

        {this.gdprStore.acceptCookies && !this.popUpStore.customPopUp ? <Newsletter key='newsletter' /> : null}

        {this.extended.$config.zento.theme.enableCustomPopUp && this.popUpStore.customPopUp ? (
          <PopUp key='popUp' />
        ) : null}
      </div>
    );
  }
}
