import { Component, BaseComponent, VariationBase } from '@zento-lib/components';
import CurrentPage from 'theme/mixins/currentPage';
import { NavigationStore } from 'theme/stores/navigation/navigation';

import type { IFooter } from './Footer.d';

/**
 * Footer
 *
 * Renders blocks information (like newsletter, social media, important links)
 */
@Component({
  mixins: [CurrentPage],
})
@VariationBase
export class Footer extends BaseComponent<IFooter, unknown> {
  protected static V = {
    footer1: () => import('./variations/footer1/footer1').then((m) => m.Footer1),
    footer2: () => import('./variations/footer2/footer2').then((m) => m.Footer2),
    footer3: () => import('./variations/footer3/footer3').then((m) => m.Footer3),
    footer4: () => import('./variations/footer4/footer4').then((m) => m.Footer4),
  };

  protected static T = {
    btn: 'footer_newsletter_btn',
    facebook: 'footer_social_facebook',
    instagram: 'footer_social_instagram',
    pinterest: 'footer_social_pinterest',
    youtube: 'footer_social_youtube',
    twitter: 'footer_social_twitter',
    copyright: 'footer_copyright',
    poweredBy: 'footer_poweredby',
    allRightsReserved: 'footer_all_rights_reserved',
    poweredByLink: 'footer_poweredby_link',
    poweredByLinkLabel: 'footer_poweredby_link_label',
    newsletterInputPlaceholder: 'newsletter_input_placeholder',
    newsletterInputLabel: 'newsletter_input_label',
    newsletterCheckboxPlaceholder: 'newsletter_checkbox_label',
  };

  protected navigationStore = new NavigationStore();

  data() {
    return {
      footerConfig: this.extended.$config.zento.footerConfig,
    };
  }

  /**
   * Determines account pages
   */
  protected get account() {
    const accountUrl = this.$route.path.includes('my-account');

    if (accountUrl) {
      return true;
    }

    return false;
  }

  /**
   * Fetches footer related data
   */
  async fetchFooterInfo(storeId?: number) {
    return this.navigationStore.fetchFooterData(storeId);
  }

  async serverPrefetch() {
    return await this.fetchFooterInfo();
  }

  beforeMount() {
    this.onBroadcast('store-view-changed', async (storeId) => {
      await this.fetchFooterInfo(storeId);
    });
  }

  async mounted() {
    if (!this.navigationStore.footerNavigation.length) {
      await this.fetchFooterInfo();
    }
  }

  beforeDestroy() {
    this.offBroadcast('store-view-changed');
  }
}
