import { Component, BaseComponent, Prop } from '@zento-lib/components';
import type { I18nMessage } from '@zento-lib/components/Base/types';

import { InnerHTML } from '../../../atom/InnerHTML';
import { Button } from '../../../atom/Button/Button';
// import { MainImage } from '../../../atom/MainImage/MainImage';
import { NewsletterTypes } from '../../types/Newsletter';

import type { INewsletterPrePopUp } from './NewsletterPrePopUp.d';
import style from './style.scss';

/**
 * Newsletter Pre Pop Up
 *
 * Newsletter Pre Pop Up component rendering newsletter content
 */
@Component({})
export class NewsletterPrePopUp extends BaseComponent<INewsletterPrePopUp, unknown> {
  /**
   * Determines if is mobile
   */
  @Prop({ type: Boolean, required: true })
  isMobile: boolean;

  /**
   * Determines newsletter list options
   */
  @Prop({ type: Object, required: true })
  storeConfig: NewsletterTypes;

  /**
   * A text representing label for closing newsletter
   */
  @Prop({ type: [Object, String], required: true })
  closeButtonLabel: I18nMessage;

  /**
   * Show newsletter form.
   */
  @Prop({ type: Function, default: () => undefined, required: true })
  showNewsletter: (ev: Event) => void;

  /**
   * Close newsletter.
   */
  @Prop({ type: Function, default: () => undefined, required: true })
  closeNewsletter: (ev: Event) => void;

  render() {
    const image = {
      src: this.storeConfig.newsletterFormsPrePopupImage,
      loading: this.storeConfig.newsletterFormsPrePopupImage,
    };

    return (
      <div
        class={{
          [style.newsletterWrapper]: true,
          [style.bottomRight]: this.storeConfig.newsletterFormsPrePopupTemplate === 'bottom-right',
          [style.topBar]: this.storeConfig.newsletterFormsPrePopupTemplate === 'top-bar',
          [style.bottomBar]: this.storeConfig.newsletterFormsPrePopupTemplate === 'bottom-bar',
        }}>
        {this.storeConfig.newsletterFormsPrePopupTitle &&
        this.storeConfig.newsletterFormsPrePopupTemplate === 'bottom-right' &&
        !this.isMobile ? (
          <header class={style.headerTitle}>
            <h3>{this.getTranslation(this.storeConfig.newsletterFormsPrePopupTitle)}</h3>
          </header>
        ) : null}

        <div class={style.newsletterContent}>
          {this.storeConfig.newsletterFormsPrePopupImage ? (
            <div class={style.image}>
              {/* TODO: temporary added img tag until newsletter image support cache path and webp format, then MainImage Component can be used */}
              <img
                src={this.extended.$config.images.baseUrl + image.src}
                srcset={
                  this.extended.$config.images.baseUrl +
                  image.src +
                  ' 1x, ' +
                  this.extended.$config.images.baseUrl +
                  image.src +
                  ' 2x'
                }
                alt={this.storeConfig.newsletterFormsPrePopupTitle}
                loading='lazy'
              />
              {/* <MainImage
                image={image}
                folder=''
                alt={this.storeConfig.newsletter_forms_pre_popup_title}
                width={64}
                height={64}
                tabletWidth={64}
                tabletHeight={64}
                desktopWidth={64}
                desktopHeight={64}
                key='main-image'
              /> */}
            </div>
          ) : null}

          <div class={style.content}>
            {!this.isMobile ? (
              <div>
                {this.storeConfig.newsletterFormsPrePopupTitle &&
                this.storeConfig.newsletterFormsPrePopupTemplate !== 'bottom-right' &&
                !this.isMobile ? (
                  <h3 class={style.title}>{this.getTranslation(this.storeConfig.newsletterFormsPrePopupTitle)}</h3>
                ) : null}

                <InnerHTML contents={this.storeConfig.newsletterFormsPrePopupTemplate} key='pop-up-content' />
              </div>
            ) : (
              <h3>{this.getTranslation(this.storeConfig.newsletterFormsPrePopupTitle)}</h3>
            )}
          </div>

          <Button
            class={{
              [style.newsletterBtn]: true,
              [style.btnMobile]: this.isMobile,
            }}
            data-newsletter='newsletter-pre-pop-up'
            styleType='primary'
            ariaLabel={this.storeConfig.newsletterFormsPrePopupButtonLabel}
            handler={this.showNewsletter}>
            {this.getTranslation(this.storeConfig.newsletterFormsPrePopupButtonLabel)}
          </Button>

          <span
            onClick={this.closeNewsletter}
            aria-label={this.getTranslation(this.closeButtonLabel)}
            data-testid='newsletter-pre-pop-up-close'
            class={style.close}
          />
        </div>
      </div>
    );
  }
}
