import { BaseComponent, Component } from '@zento-lib/components';
import { Footer } from '@zento/modules/organism/Footer/Footer';
import { OrderConfirmation } from '@zento/modules/organism/Checkout/OrderConfirmation/OrderConfirmation';
import { CookieNotification } from '@zento/modules/molecule/CookieNotification/CookieNotification';
import { Notification } from '@zento/modules/molecule/Notification/Notification';
import { NewNotification } from '@zento/modules/molecule/NewNotification/NewNotification';
import { CompareWidget } from '@zento/modules/molecule/CompareWidget/CompareWidget';
import { Overlay } from '@zento/modules/atom/Overlay/Overlay';
import { Loader } from '@zento/modules/atom/Loader/Loader';
import { BackToTop } from '@zento/modules/atom/BackToTop/BackToTop';

import type { IBottom } from './Bottom.d';

@Component({})
export class Bottom extends BaseComponent<IBottom, unknown> {
  private static T = {
    cookieDetailsLinkText: 'cookie_details_link_text',
    cookieMessage: 'cookie_message',
  };

  data() {
    return {
      loadOrderConfirmation: false,
      ordersData: [],
    };
  }

  onOrderConfirmation(payload) {
    this.$data.loadOrderConfirmation = true;
    this.$data.ordersData = payload;
    this.broadcast('modal-show', 'modal-order-confirmation');
  }

  mounted() {
    this.$store.dispatch('wishlist/load');
    this.$store.dispatch('compare/load');
  }

  beforeMount() {
    let persistProgress = false;

    // Progress bar on top of the page
    this.$router.beforeEach((to, from, next) => {
      // Continue to next page
      if (
        to.matched.some(
          (r) => r.components?.default?.name === 'Category' || r.components?.default?.name === 'Search',
        ) &&
        from.matched.some((r) => r.components?.default?.name === 'Category' || r.components?.default?.name === 'Search')
      ) {
        if (
          typeof location !== 'undefined' &&
          location.pathname !== '/' &&
          location.pathname.startsWith(to.path || to.fullPath) &&
          location.pathname === (to.path || to.fullPath) &&
          (to.path || to.fullPath) === (from.path || from.fullPath)
        ) {
          next(false);
        } else {
          persistProgress = true;
          this.progressBar();

          next();
        }
      } else {
        this.progressBar();

        next();
      }
    });

    this.$router.afterEach(() => {
      if (!persistProgress) {
        // Finish the progress bar loading
        (this as any).$Progress.finish();
        persistProgress = false;
      }
    });

    this.onBroadcast('offline-order-confirmation', this.onOrderConfirmation);
  }

  beforeDestroy() {
    this.offBroadcast('offline-order-confirmation', this.onOrderConfirmation);
  }

  render() {
    const config = this.extended.$config.zento;
    const themeConfig = config.theme;

    return (
      <span>
        <Footer key='footer' />
        <Notification key='notifications' />
        <NewNotification key='new-notifications' />

        {config.compare.enabled ? <CompareWidget key='compare-widget' /> : null}

        <CookieNotification
          detailsLink={themeConfig.cookiePopUpLink ?? ''}
          detailsLinkText={{ id: Bottom.T.cookieDetailsLinkText }}
          message={{ id: Bottom.T.cookieMessage }}
          key='cookie-notification'
        />
        <Overlay key='overlay' />
        <Loader key='loader' />

        {themeConfig.showBackToTopBtn ? (
          <BackToTop
            mobileRight={themeConfig.backToTop.mobileRight}
            mobileLeft={themeConfig.backToTop.mobileLeft}
            right={themeConfig.backToTop.right}
            left={themeConfig.backToTop.left}
            mobileBottom={themeConfig.backToTop.mobileBottom}
            productMobileBottom={themeConfig.backToTop.productMobileBottom}
            bottom={themeConfig.backToTop.bottom}
            key='back-to-top'
          />
        ) : null}

        {this.$data.loadOrderConfirmation ? (
          <OrderConfirmation ordersData={this.$data.ordersData} key='order-confirm' />
        ) : null}

        <vue-progress-bar key='progress-bar' />
      </span>
    );
  }

  private progressBar() {
    // Start the progress bar loading
    (this as any).$Progress.start();

    // Increase the progress bar by a certain %
    (this as any).$Progress.increase(60);

    // Cause the progress color to permanently change
    (this as any).$Progress.setColor(this.extended.$config.zento.progressBar.color);
  }
}
