import { Form } from 'theme/components/Form/Form';
import { Checkbox } from 'theme/components/Form/Checkbox';
import { Component, BaseComponent, Prop } from '@zento-lib/components';
import type { I18nMessage } from '@zento-lib/components/Base/types';
import { GDPRStore } from 'theme/stores/gdpr/gdpr';

import { Button } from '../../atom/Button/Button';
import { Link } from '../../atom/Link/Link';
import { Accordion } from '../../atom/Accordion/Accordion';
import { AccordionItem } from '../../atom/Accordion/Item';
import { InnerHTML } from '../../atom/InnerHTML';
import { Overlay } from '../../atom/Overlay/Overlay';

import type { ICookieNotification } from './CookieNotification.d';
import style from './style.scss';

/**
 * Cookie Notification
 *
 * Renders information about site cookies for the end user
 */
@Component({})
@Form()
export class CookieNotification extends BaseComponent<ICookieNotification, unknown> {
  private static T = {
    refuseCookies: 'refuse_cookie',
    saveSelectedCookie: 'save_selected_cookie',
    acceptAllCookies: 'accept_all_cookies',
    manageCookies: 'manage_cookies',
    cookiePreferences: 'cookie_preferences',
    manageCookiePreferences: 'manage_cookie_preferences',
    ariaLabel: 'sidebar_menu_close_button',
  };

  /**
   * A text representing site cookie link label
   */
  @Prop({ type: [Object, String], default: undefined })
  detailsLinkText?: I18nMessage;

  /**
   * Link to specific cookie page
   */
  @Prop({ type: String, default: '/i/privacy-policy' })
  detailsLink?: string;

  /**
   * A text representing site cookie information
   */
  @Prop({ type: [Object, String], default: undefined })
  message?: I18nMessage;

  /**
   * Un-watch function keeper
   */
  private unwatch: Array<() => void> = [];
  private gdprStore = new GDPRStore();

  data() {
    return {
      isOpened: false,
      toggle: false,
      showOverlay: false,
    };
  }

  beforeMount() {
    this.saveCookies = this.saveCookies.bind(this);
    this.acceptAllCookies = this.acceptAllCookies.bind(this);
    this.refuseCookies = this.refuseCookies.bind(this);
    this.toggleDrawer = this.toggleDrawer.bind(this);
  }

  async mounted() {
    if (!this.gdprStore.categories.length || !this.gdprStore.platforms.length) {
      await this.getConfigurationInfo();
    }

    if (!this.gdprStore.cookie) {
      await this.gdprStore.getFromStorage();
    }

    if (this.gdprStore.cookie?.fallback) {
      // Show the notification when there is no previous user selection available
      this.$data.isOpened = true;
      // (this.gdprStore.cookie.fallback as any) = false;
    }

    // Save the cookie on navigation for acceptance cases // TODO: check if needed
    // if (!this.gdprStore.cookie && this.gdprStore.popup.active && this.gdprStore.popup.acceptance) {
    //   this.unwatch.push(
    //     this.$watch('$route', async () => {
    //       await this.saveCookies();
    //     }),
    //   );
    // }

    setTimeout(() => {
      this.defaultConsent();
    }, 1000);

    // Open popup if footer link is clicked
    this.onBroadcast('cookie/open-popup', () => {
      this.$data.isOpened = true;
    });
  }

  beforeDestroy() {
    this.unwatch.forEach((h) => h());
    this.offBroadcast('cookie/open-popup');
  }

  async serverPrefetch() {
    return await this.getConfigurationInfo();
  }

  render() {
    if (!this.gdprStore?.popup?.active) {
      return;
    }

    return (
      <div key='cookies-main-wrapper'>
        <transition
          name='fade-in-down'
          enterActiveClass={style.fadeInDownEnterActive}
          leaveActiveClass={style.fadeInDownLeaveActive}
          key='cookies-information'>
          {this.$data.isOpened ? (
            <div class={style.cookieNotification} data-gdpr='gdpr' key='gdpr'>
              <div class={style.container} key='cookie-contaier'>
                <div class={style.cookieNotificationWrapper} key='cookie-wrapper'>
                  <div class={style.cookieNotificationInfo} key='cookie-information'>
                    <span>{this.getTranslation(this.message)}</span>
                    <Link to={this.detailsLink}>{this.getTranslation(this.detailsLinkText)}</Link>
                  </div>
                </div>
                <div class={style.cookieNotificationBtns} key='cookie-btns'>
                  <Button
                    styleType='ghost'
                    handler={this.refuseCookies}
                    class={style.refuse}
                    dataTestId='refuseCookies'
                    key='refuse-cookies'>
                    {this.getTranslation({ id: CookieNotification.T.refuseCookies })}
                  </Button>

                  <Button
                    styleType='primary'
                    handler={this.toggleDrawer}
                    class={style.manage}
                    dataTestId='manageCookies'
                    key='toggle-cookies'>
                    {this.getTranslation({ id: CookieNotification.T.manageCookies })}
                  </Button>

                  {this.gdprStore.popup.displayMode !== 'explicit' ? (
                    <Button
                      styleType='primary'
                      handler={this.acceptAllCookies}
                      class={style.accept}
                      dataTestId='acceptCookies'
                      key='accept-all-cookies'>
                      {this.getTranslation({ id: CookieNotification.T.acceptAllCookies })}
                    </Button>
                  ) : null}
                </div>
              </div>
            </div>
          ) : null}
        </transition>
        <transition
          name='slide-in-right'
          enterActiveClass={style.slideInRightEnterActive}
          leaveActiveClass={style.slideInRightLeaveActive}
          key='manage-cookies'>
          {this.$data.toggle ? (
            <div class={style.cookiesDrawer} key='cookie-drawer'>
              <div class={style.cookiesSidebar} key='cookie-sidebar'>
                <div class={style.cookiesHeader} key='cookie-header'>
                  <h2 class={style.cookiesTitle} key='cookie-title'>
                    {this.getTranslation({ id: CookieNotification.T.cookiePreferences })}
                  </h2>
                  <span
                    onClick={this.closeDrawer}
                    aria-label={this.getTranslation({ id: CookieNotification.T.ariaLabel })}
                    data-testId='cookies-drawer-close'
                    class={style.cookieClose}
                    key='cookie-close'
                  />
                </div>
                <div class={style.cookiesSidebarWrapper} key='cookie-sidebar-wrapper'>
                  <div class={style.cookieNotificationInfo} key='cookie-information'>
                    <span>{this.getTranslation(this.message)}</span>
                    <Link to={this.detailsLink}>{this.getTranslation(this.detailsLinkText)}</Link>
                  </div>

                  {this.gdprStore.popup.displayMode !== 'explicit'
                    ? [
                        <Button styleType='ghost' handler={this.refuseCookies} key='drawer-refuse-cookies'>
                          {this.getTranslation({ id: CookieNotification.T.refuseCookies })}
                        </Button>,
                        <Button styleType='primary' handler={this.acceptAllCookies} key='drawer-accept-all-cookies'>
                          {this.getTranslation({ id: CookieNotification.T.acceptAllCookies })}
                        </Button>,
                      ]
                    : null}

                  <div class={style.cookieSubtitle} key='cookie-subtitle'>
                    <h3>{this.getTranslation({ id: CookieNotification.T.manageCookiePreferences })}</h3>
                  </div>

                  <Accordion activeTab={0} class={style.cookiesAccordion} key='cookies-data'>
                    {this.gdprStore.categories.map((c) => {
                      const obj = this.gdprStore.cookie[c.title] || { checked: false, required: false };

                      return (
                        <AccordionItem
                          ref={c.title}
                          id={c.title}
                          class={{ [style.readOnly]: obj.required }}
                          key={c.id + c.title}>
                          <span slot='accordionTrigger' key={c.id + '-title'}>
                            <Checkbox
                              state={obj}
                              valueKeeper='checked'
                              name={c.title + '-cookie'}
                              required={obj.required}
                              label={c.title}
                              class={{
                                [style.readOnlyCheckbox]: obj.required,
                                [style.noCheckbox]: this.gdprStore.popup.displayMode === 'all',
                              }}
                            />
                          </span>
                          <span slot='accordionContent' class={style.cookieDetails} key={c.id + '-content'}>
                            <InnerHTML contents={c.description ?? ''} key={c.id + '-html'} />

                            <table>
                              <tbody>
                                {this.gdprStore.platforms
                                  .filter((p) => p.active && p.id && p.categoryLabel === c.title)
                                  .map((p) => {
                                    return (
                                      <tr>
                                        <td>
                                          <span>{p.__typename.replace(/([a-z])([A-Z])/g, '$1 $2')}</span>
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                          </span>
                        </AccordionItem>
                      );
                    })}
                  </Accordion>
                </div>

                <div class={style.cookiesBtns} key='cookies-btns'>
                  {this.gdprStore.popup.displayMode !== 'all' ? (
                    <Button styleType='primary' handler={this.personalizationConsentGranted} key='save-cookies'>
                      {this.getTranslation({ id: CookieNotification.T.saveSelectedCookie })}
                    </Button>
                  ) : (
                    <Button styleType='primary' handler={this.acceptAllCookies} key='accept-all'>
                      {this.getTranslation({ id: CookieNotification.T.acceptAllCookies })}
                    </Button>
                  )}
                </div>
              </div>

              {this.$data.showOverlay ? (
                <div onClick={this.closeDrawer} class={style.showOverlay} key='cookies-overlay'>
                  <Overlay />
                </div>
              ) : null}
            </div>
          ) : null}
        </transition>
      </div>
    );
  }

  private async getConfigurationInfo() {
    return this.gdprStore.runInParallel(this.gdprStore.fetchClaims(), this.gdprStore.fetchCategories());
  }

  public gtag(...args) {
    if (args) {
      (window as any).dataLayer = (window as any).dataLayer || [];

      dataLayer.push(arguments); // eslint-disable-line
    }
  }

  /**
   * Set cookie with selected values and consent selected values
   */
  private async defaultConsent() {
    const consentMode = {
      ad_user_data: 'denied',
      ad_personalization: 'denied',
      ad_storage: 'denied',
      analytics_storage: 'denied',
    };

    if (localStorage.getItem('consentMode') === null) {
      Object.values(this.gdprStore.allClaims).forEach((p: any) => {
        if (p.active && (p.__typename === 'GoogleTagManager' || p.__typename === 'AdWords')) {
          Object.values(this.gdprStore.cookie).forEach((c: any) => {
            if (c.checked && c.id === (p.gtmAdUserData || p.adwordsAdUserData)) {
              Object.assign(consentMode, { ad_user_data: 'granted' });
            }

            if (c.checked && c.id === (p.gtmAdPersonalization || p.adwordsAdPersonalization)) {
              Object.assign(consentMode, { ad_personalization: 'granted' });
            }

            if (c.checked && c.id === (p.gtmAdStorage || p.adwordsAdStorage)) {
              Object.assign(consentMode, { ad_storage: 'granted' });
            }

            if (c.checked && c.id === (p.gtmAnalyticsStorage || p.adwordsAnalyticsStorage)) {
              Object.assign(consentMode, { analytics_storage: 'granted' });
            }
          });
        }
      });

      this.gtag('consent', 'update', consentMode);
    } else {
      this.gtag('consent', 'update', JSON.parse(localStorage.getItem('consentMode')));
    }
  }

  /**
   * Set cookie with selected values and consent selected values
   */
  private async personalizationConsentGranted() {
    const consentMode = {
      ad_user_data: 'denied',
      ad_personalization: 'denied',
      ad_storage: 'denied',
      analytics_storage: 'denied',
    };

    Object.values(this.gdprStore.allClaims).forEach((p: any) => {
      if (p.active && (p.__typename === 'GoogleTagManager' || p.__typename === 'AdWords')) {
        Object.values(this.gdprStore.cookie).forEach((c: any) => {
          if (c.checked && c.id === (p.gtmAdUserData || p.adwordsAdUserData)) {
            Object.assign(consentMode, { ad_user_data: 'granted' });
          }

          if (c.checked && c.id === (p.gtmAdPersonalization || p.adwordsAdPersonalization)) {
            Object.assign(consentMode, { ad_personalization: 'granted' });
          }

          if (c.checked && c.id === (p.gtmAdStorage || p.adwordsAdStorage)) {
            Object.assign(consentMode, { ad_storage: 'granted' });
          }

          if (c.checked && c.id === (p.gtmAnalyticsStorage || p.adwordsAnalyticsStorage)) {
            Object.assign(consentMode, { analytics_storage: 'granted' });
          }
        });
      }
    });

    localStorage.setItem('consentMode', JSON.stringify(consentMode));

    this.gtag('consent', 'update', consentMode);

    await this.saveCookies();
  }

  /**
   * Set cookie with selected values
   */
  private async saveCookies() {
    (this.gdprStore.cookie.fallback as any) = false;
    await this.gdprStore.setInStorage(this.gdprStore.cookie);

    this.$data.isOpened = false;
    this.$store.commit('ui/setOverlay', false);
    this.closeDrawer();
  }

  /**
   * Accept all cookies and set them
   */
  private async acceptAllCookies() {
    this.gdprStore.categories.forEach((c) => {
      this.gdprStore.cookie[c.title].checked = true;
    });

    this.gdprStore.acceptCookies = true;

    const consentMode = {
      ad_user_data: 'granted',
      ad_personalization: 'granted',
      ad_storage: 'granted',
      analytics_storage: 'granted',
    };

    localStorage.setItem('consentMode', JSON.stringify(consentMode));

    this.gtag('consent', 'update', consentMode);

    await this.saveCookies();
  }

  /**
   * Refuse cookies and set only required ones
   */
  private async refuseCookies() {
    this.gdprStore.categories.forEach((c) => {
      if (this.gdprStore.cookie[c.title].required) {
        this.gdprStore.cookie[c.title].checked = true;
      } else {
        this.gdprStore.cookie[c.title].checked = false;
      }
    });

    await this.saveCookies();

    const consentMode = {
      ad_user_data: 'denied',
      ad_personalization: 'denied',
      ad_storage: 'denied',
      analytics_storage: 'denied',
    };

    localStorage.setItem('consentMode', JSON.stringify(consentMode));

    this.gtag('consent', 'update', consentMode);
  }

  private toggleDrawer() {
    this.$data.toggle = !this.$data.toggle;
    this.$data.showOverlay = !this.$data.showOverlay;
    document.body.style.overflow = 'hidden';
  }

  private closeDrawer() {
    this.$data.toggle = false;
    this.$data.showOverlay = false;
    document.body.style.overflow = 'visible';
  }
}
