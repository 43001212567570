import { BaseComponent, Component } from '@zento-lib/components';
import { Header } from '@zento/modules/organism/Header/Header';

import type { ITop } from './Top.d';

@Component({})
export class Top extends BaseComponent<ITop, unknown> {
  render() {
    return <Header key='header' />;
  }
}
