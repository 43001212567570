import { Component, BaseComponent, Prop, namespace } from '@zento-lib/components';
import type { I18nMessage } from '@zento-lib/components/Base/types';
import { Form } from 'theme/components/Form/Form';
import { IFormValidation } from 'theme/components/Form/types';
import { Input } from 'theme/components/Form/Input';
import { Checkbox } from 'theme/components/Form/Checkbox';
import { NewsletterStore } from 'theme/stores/newsletter/newsletter';
import { GDPRStore } from 'theme/stores/gdpr/gdpr';
import { AppContextStore, KEY as appContextKey } from 'theme/@types/zento/stores/applicationContext';

import { NewsletterMixin } from '../../../../themes/default/mixins/newsletter/newsletter';
import { Button } from '../../../atom/Button/Button';

import type { IFooterNewsletter } from './Newsletter.d';
import style from './style.scss';

const appContextStore = namespace<AppContextStore>(appContextKey);

interface INewsletterMixin {
  subscribeNewsletter(email: string): any;
}

/**
 * Newsletter
 *
 * Allows end users to subscribe to newsletter
 */
@Component({
  mixins: [NewsletterMixin],
})
@Form()
export class FooterNewsletter extends BaseComponent<IFooterNewsletter, INewsletterMixin & IFormValidation> {
  /**
   *  A text representing newsletter CTA information
   */
  @Prop({ type: [Object, String], default: '' })
  newsletterCtaLabel?: I18nMessage;

  /**
   * Determines newsletter button label
   */
  @Prop({ type: [Object, String], default: '' })
  newsletterBtnLabel?: I18nMessage;

  /**
   * Determines newsletter input placeholder
   */
  @Prop({ type: [Object, String], default: '' })
  inputPlaceHolder?: I18nMessage;

  /**
   * Determines newsletter input label
   */
  @Prop({ type: [Object, String], default: '' })
  inputLabel?: I18nMessage;

  /**
   * Determines newsletter checkbox label
   */
  @Prop({ type: [Object, String], default: '' })
  checkboxLabel?: I18nMessage;

  @appContextStore.Getter('isMobile')
  isMobile: boolean;

  protected newsletterStore = new NewsletterStore();
  private gdprStore = new GDPRStore();

  data() {
    return {
      email: '',
      newsletterCheck: this.extended.$config.zento.footerConfig.newsletterChecked,
    };
  }

  /**
   * Subscribe to newsletter
   */
  subscribeToNewsletter(ev: Event) {
    ev.preventDefault();

    this.extended.subscribeNewsletter(this.$data.email);
    this.broadcast('analytics/newsletter-subscribe', { options: this.gdprStore.allClaims.adWords });
  }

  onLoggedIn() {
    this.$data.email = this.$store.state.user.current.email;
  }

  /**
   * Propagate email subscribed to parent component
   */
  propagateEmailValue() {
    this.newsletterStore.emailValue = this.$data.email;
  }

  beforeMount() {
    if (this.$store.state.user.current) this.onLoggedIn();

    this.onBroadcast('user-after-loggedin', this.onLoggedIn);
  }

  beforeDestroy() {
    this.offBroadcast('user-after-loggedin', this.onLoggedIn);
  }

  render() {
    const isInvalid = !this.extended.zValidation.valid;
    const config = this.extended.$config.zento.footerConfig;

    return (
      <form
        class={{
          [style.newsletterWrapper]: true,
          [style.buttonOutside]: config.newsletterBtnOutside,
          [style.topCheckbox]: config.newsletterCheckboxTop,
        }}>
        <div class={style.newsletterBox} key='newsletter-box'>
          {
            <Input
              state={this.$data}
              valueKeeper='email'
              name='email'
              validateOn='input'
              minLength={5}
              pattern='email'
              placeholder={!config.newsletterLabel ? this.getTranslation(this.inputPlaceHolder) : ''}
              labelStyle={config.newsletterInputStyle}
              onBlur={this.propagateEmailValue}
              class={style.newsletterInput}
              data-newsletter='newsletter-input'
              dataTestId='emailNewsletter'>
              {config.newsletterLabel ? (
                <span
                  slot='label'
                  class={{
                    [style.newsletterLabel]: true,
                    [style.newsletterLabelMiddle]: config.newsletterLabel,
                  }}>
                  {this.getTranslation(this.inputLabel)}
                </span>
              ) : null}
              <Button
                data-newsletter='newsletter-button'
                disabled={isInvalid || this.newsletterStore.loading}
                loader={this.newsletterStore.loading}
                styleType={this.inputLabel !== '' ? 'secondary' : 'tertiary'}
                ariaLabel={this.newsletterBtnLabel}
                handler={this.subscribeToNewsletter}
                slot={config.newsletterBtnOutside || this.isMobile ? 'after' : 'inside'} // TODO: Find a better solution for mobile because "after" slot interferes with styles
                class={{ [style.button]: true, [style.hasLabel]: !!this.inputLabel }}
                dataTestId='submitEmailNewsletter'>
                {this.getTranslation(this.newsletterBtnLabel)}
              </Button>
            </Input>
          }
        </div>

        {config.newsletterCheckbox ? (
          <Checkbox
            state={this.$data}
            valueKeeper='newsletterCheck'
            name='newsletterCheck'
            type='checkbox'
            id='newsletterCheck'
            validateOn='change'
            required={true}
            label={this.getTranslation(this.checkboxLabel)}
            customLabel={config.newsletterCheckboxCustomLabel}
            showMoreContent={true}
            class={style.newsletterCheck}
            dataTestId='checkboxNewsletter'
          />
        ) : null}
      </form>
    );
  }
}
