import { Component, BaseComponent } from '@zento-lib/components';
import { NotificationStore } from 'theme/stores/notification/notification';

import { INewNotification } from './NewNotification.d';
import style from './style.scss';

/**
 * Notification
 *
 * Renders helping information about a current action performed by end user
 */
@Component({})
export class NewNotification extends BaseComponent<INewNotification, unknown> {
  private notificationStore = new NotificationStore();

  beforeMount() {
    this.execAction = this.execAction.bind(this);
  }

  execAction(action: Record<string, any>, index: number) {
    if (action.action) {
      // for backward compatibility
      if (action.action === 'close') {
        this.notificationStore.removeNotification(index);
      } else {
        action.action();
      }
    }

    this.notificationStore.removeNotification(index);
  }

  render() {
    return (
      <div class={style.notificationsWrapper} data-testId='notificationsWrapper'>
        <transition-group
          name='fade-in-down'
          enterActiveClass={style.fadeInDownEnterActive}
          leaveActiveClass={style.fadeInDownLeaveActive}>
          {this.notificationStore.allNotifications.map((notification, index) => {
            return (
              <div key={index} class={style.notificationContainer}>
                <div
                  class={{
                    [style.notification]: true,
                    [style.closeBtn]: notification.action1,
                  }}>
                  <div
                    data-testId='notificationMessage'
                    class={{
                      [style.message]: true,
                      [style.onlyMessage]: !notification.action2 || !notification.action1,
                    }}>
                    {notification.message}
                  </div>
                  <div class={style.actions}>
                    {notification.action2 ? (
                      <div class={style.notificationBtnContainer}>
                        <div
                          class={[style.notificationBtn, style.notificationBtnAction]}
                          id='notificationAction2'
                          data-testId='notificationAction2'
                          onClick={() => this.execAction(notification.action2, index)}>
                          {notification.action2.label}
                        </div>
                      </div>
                    ) : null}
                    {notification.action1 ? (
                      <div class={[style.notificationBtnContainer, style.notificationBtnCloseContainer]}>
                        <div
                          class={[style.notificationBtn, style.notificationBtnClose]}
                          id='notificationAction1'
                          data-testId='notificationAction1'
                          onClick={() => this.execAction(notification.action1, index)}>
                          {notification.action1.label}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            );
          })}
        </transition-group>
      </div>
    );
  }
}
