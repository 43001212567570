import { Component, BaseComponent, namespace } from '@zento-lib/components';
import { StoreConfig } from 'theme/stores/storeConfig/storeConfig';
import { NewsletterStore } from 'theme/stores/newsletter/newsletter';
import { PopUpStore } from 'theme/stores/popUp/popUp';
import { AppContextStore, KEY as appKey } from 'theme/@types/zento/stores/applicationContext';

import { Modal } from '../../organism/Modal/Modal';

import { NewsletterPopUp } from './NewsletterPopUp/NewsletterPopUp';
import { NewsletterPrePopUp } from './NewsletterPrePopUp/NewsletterPrePopUp';
import type { INewsletter } from './Newsletter.d';
import style from './style.scss';

const appContextStore = namespace<AppContextStore>(appKey);

/**
 * Newsletter
 *
 * Newsletter pop up/drawer component rendering articles items
 */
@Component({})
export class Newsletter extends BaseComponent<INewsletter, unknown> {
  protected static T = {
    btn: 'footer_newsletter_btn',
    newsletterClose: 'newsletter_close_icon',
    newsletterInputPlaceholder: 'newsletter_input_placeholder',
    newsletterInputLabel: 'newsletter_input_label',
    newsletterCheckboxPlaceholder: 'newsletter_checkbox_label',
    newsletterSuccessTitle: 'newsletter_success_title',
    newsletterSuccessMessage: 'newsletter_success_message',
  };

  @appContextStore.Getter('isMobile')
  private isMobile: boolean;

  protected storeConfigData = new StoreConfig();
  protected newsletterStore = new NewsletterStore();
  private popUpStore = new PopUpStore();

  storeConfig = this.storeConfigData.storeConfigurations;

  data() {
    return {
      newsletterPopUp: false,
      newsletterToggle: false,
      newsletterForm: false,
    };
  }

  /**
   * Set newsletter cookie
   */
  setCookie(name: string, value: boolean, days: number) {
    let expires = '';

    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toUTCString();
    }

    document.cookie = name + '=' + (value || '') + expires + '; path=/';
  }

  /**
   * Get newsletter cookie
   */
  getCookie(name: string) {
    const nameEQ = name + '=';
    const ca = document.cookie.split(';');

    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }

    return null;
  }

  /**
   * Show newsletter pre pop up
   */
  showNewsletterPrePopUp() {
    setTimeout(() => {
      this.$data.newsletterForm = true;

      this.setCookie('newsletter-pre-pop-up', true, this.storeConfig.newsletterFormsPrePopupcookieLifetime);
    }, this.storeConfig.newsletterFormsPrePopupTimeDelay * 1000);
  }

  /**
   * Check pop up template and set correct styling
   */
  checkTemplate() {
    this.newsletterStore.drawerState = true;

    if (this.storeConfig.newsletterFormsPopupTemplate === 'pop-up' && !this.isMobile) {
      this.$nextTick(() => this.broadcast('modal-show', 'modal-newsletter'));
    } else if (
      this.storeConfig.newsletterFormsPopupEnabled === 1 &&
      (this.storeConfig.newsletterFormsPopupTemplate === 'drawer' ||
        this.storeConfig.newsletterFormsPopupTemplate === 'pop-up')
    ) {
      this.$store.commit('ui/setOverlay', true);
      document.body.style.overflow = 'hidden';
    }
  }

  /**
   * Show newsletter pop up
   */
  showNewsletterPopUp() {
    setTimeout(() => {
      this.$data.newsletterPopUp = true;

      this.setCookie('newsletter-pop-up', true, this.storeConfig.newsletterFormsPopupCookieLifetime);

      this.checkTemplate();
    }, this.storeConfig.newsletterFormsPopupTimeDelay * 1000);
  }

  /**
   * Show newsletter
   */
  showNewsletter() {
    this.$data.newsletterToggle = true;
    this.$data.newsletterForm = false;

    this.checkTemplate();
  }

  /**
   * Close newsletter
   */
  closeNewsletter() {
    this.popUpStore.customPopUp = true;
    this.$data.newsletterForm = false;
    this.$data.newsletterPopUp = false;
    this.$data.newsletterToggle = false;
    this.newsletterStore.drawerState = false;
    this.$store.commit('ui/setOverlay', false);
    document.body.style.overflow = 'visible';
  }

  mounted() {
    const cookiePrePopUp = this.getCookie('newsletter-pre-pop-up');
    const cookiePopUp = this.getCookie('newsletter-pop-up');

    if (!cookiePopUp && this.storeConfig.newsletterFormsPrePopupEnabled !== 1) {
      this.showNewsletterPopUp();
    } else if (!cookiePrePopUp && this.storeConfig.newsletterFormsPrePopupEnabled === 1) {
      this.showNewsletterPrePopUp();
    }
  }

  beforeMount() {
    this.showNewsletter = this.showNewsletter.bind(this);
  }

  render() {
    return this.storeConfig.newsletterFormsPopupEnabled === 1 ? (
      <div key='newsletter'>
        {this.storeConfig.newsletterFormsPopupTemplate === 'pop-up' && !this.isMobile ? (
          <Modal name='modal-newsletter' width={640}>
            {this.$data.newsletterToggle || this.$data.newsletterPopUp ? (
              <NewsletterPopUp
                isMobile={this.isMobile}
                storeConfig={this.storeConfig}
                closeNewsletter={this.closeNewsletter}
                closeButtonLabel={{ id: Newsletter.T.newsletterClose }}
                buttonLabel={{ id: Newsletter.T.btn }}
                newsletterInputLabel={{ id: Newsletter.T.newsletterInputLabel }}
                newsletterCheckboxPlaceholder={{ id: Newsletter.T.newsletterCheckboxPlaceholder }}
                newsletterInputPlaceholder={{ id: Newsletter.T.newsletterInputPlaceholder }}
                newsletterSuccessTitle={{ id: Newsletter.T.newsletterSuccessTitle }}
                newsletterSuccessMessage={{ id: Newsletter.T.newsletterSuccessMessage }}
                key='newsletterPopUp'
              />
            ) : null}
          </Modal>
        ) : (
          <transition
            name={
              this.storeConfig.newsletterFormsPopupTemplate === 'bottom-drawer' || this.isMobile
                ? 'slide-in-bottom'
                : 'slide-in-right'
            }
            enterActiveClass={
              this.storeConfig.newsletterFormsPopupTemplate === 'bottom-drawer' || this.isMobile
                ? style.slideInBottomEnterActive
                : style.slideInRightEnterActive
            }
            leaveActiveClass={
              this.storeConfig.newsletterFormsPopupTemplate === 'bottom-drawer' || this.isMobile
                ? style.slideInBottomLeaveActive
                : style.slideInRightLeaveActive
            }>
            {this.$data.newsletterToggle || this.$data.newsletterPopUp ? (
              <NewsletterPopUp
                isMobile={this.isMobile}
                storeConfig={this.storeConfig}
                closeNewsletter={this.closeNewsletter}
                closeButtonLabel={{ id: Newsletter.T.newsletterClose }}
                buttonLabel={{ id: Newsletter.T.btn }}
                newsletterInputLabel={{ id: Newsletter.T.newsletterInputLabel }}
                newsletterCheckboxPlaceholder={{ id: Newsletter.T.newsletterCheckboxPlaceholder }}
                newsletterInputPlaceholder={{ id: Newsletter.T.newsletterInputPlaceholder }}
                newsletterSuccessTitle={{ id: Newsletter.T.newsletterSuccessTitle }}
                newsletterSuccessMessage={{ id: Newsletter.T.newsletterSuccessMessage }}
                key='newsletterPopUp'
              />
            ) : null}
          </transition>
        )}

        {this.$data.newsletterForm ? (
          <NewsletterPrePopUp
            isMobile={this.isMobile}
            storeConfig={this.storeConfig}
            closeNewsletter={this.closeNewsletter}
            closeButtonLabel={{ id: Newsletter.T.btn }}
            showNewsletter={this.showNewsletter}
            key='newsletterPrePopUp'
          />
        ) : null}
      </div>
    ) : null;
  }
}
