import { Component, BaseComponent, Prop } from '@zento-lib/components';
import type { I18nMessage } from '@zento-lib/components/Base/types';
import { NewsletterStore } from 'theme/stores/newsletter/newsletter';
import { StoreConfig } from 'theme/stores/storeConfig/storeConfig';
import { PopUpStore } from 'theme/stores/popUp/popUp';

import { FooterNewsletter } from '../../../molecule/Footer/Newsletter/Newsletter';
import { InnerHTML } from '../../../atom/InnerHTML';
// import { MainImage } from '../../../atom/MainImage/MainImage';
import { NewsletterTypes } from '../../types/Newsletter';

import type { INewsletterPopUp } from './NewsletterPopUp.d';
import style from './style.scss';

/**
 * Newsletter Pop Up
 *
 * Newsletter Pop Up component rendering newsletter content
 */
@Component({})
export class NewsletterPopUp extends BaseComponent<INewsletterPopUp, unknown> {
  private popUpStore = new PopUpStore();

  /**
   * Determines if is mobile
   */
  @Prop({ type: Boolean, required: true })
  isMobile: boolean;

  /**
   * Determines newsletter list options
   */
  @Prop({ type: Object, required: true })
  storeConfig: NewsletterTypes;

  /**
   * A text representing label for closing newsletter
   */
  @Prop({ type: [Object, String], required: true })
  closeButtonLabel: I18nMessage;

  /**
   * A text representing label for newsletter button
   */
  @Prop({ type: [Object, String], required: true })
  buttonLabel: I18nMessage;

  /**
   * A text representing label placeholder input
   */
  @Prop({ type: [Object, String], required: true })
  newsletterInputPlaceholder: I18nMessage;

  /**
   * A text representing label for checkbox
   */
  @Prop({ type: [Object, String], required: true })
  newsletterCheckboxPlaceholder: I18nMessage;

  /**
   * A text representing success title
   */
  @Prop({ type: [Object, String], required: true })
  newsletterSuccessTitle: I18nMessage;

  /**
   * A text representing success message
   */
  @Prop({ type: [Object, String], required: true })
  newsletterSuccessMessage: I18nMessage;

  /**
   * A text representing input label
   */
  @Prop({ type: [Object, String] })
  newsletterInputLabel?: I18nMessage;

  /**
   * Close newsletter.
   */
  @Prop({ type: Function, required: true, default: () => undefined })
  closeNewsletter: (ev: Event) => void;

  protected newsletterStore = new NewsletterStore();
  protected newsletterEmail = new StoreConfig();

  /**
   * Get image sizes.
   */
  get imageSizes() {
    if (
      this.storeConfig.newsletterFormsPopupImagePosition === 'background' &&
      this.storeConfig.newsletterFormsPopupTemplate !== 'bottom-drawer'
    ) {
      if (this.isMobile) {
        return {
          height: 180,
          width: 320,
        };
      } else {
        if (this.storeConfig.newsletterFormsPopupTemplate === 'drawer') {
          return {
            height: 1000,
            width: 480,
          };
        }
        return {
          height: 640,
          width: 640,
        };
      }
    } else if (this.storeConfig.newsletterFormsPopupImagePosition === 'top') {
      return {
        height: 360,
        width: 640,
      };
    }

    return {
      height: 72,
      width: 72,
    };
  }

  render() {
    const image = {
      src: this.storeConfig.newsletterFormsPopupImage,
      loading: this.storeConfig.newsletterFormsPopupImage,
    };

    return (
      <div
        class={{
          [style.newsletterWrapper]: true,
          [style.bottomDrawer]: this.storeConfig.newsletterFormsPopupTemplate === 'bottom-drawer',
          [style.leftBottomDrawer]: this.extended.$config.zento.theme.enableCustomPopUp && !this.popUpStore.customPopUp,
          [style.popUp]: this.storeConfig.newsletterFormsPopupTemplate === 'pop-up',
          [style.drawer]: this.storeConfig.newsletterFormsPopupTemplate === 'drawer',
          [style.backgroundImage]:
            !this.isMobile &&
            this.storeConfig.newsletterFormsPopupImagePosition === 'background' &&
            this.storeConfig.newsletterFormsPopupImage,
          [style.topImage]:
            this.storeConfig.newsletterFormsPopupImagePosition === 'top' && this.storeConfig.newsletterFormsPopupImage,
          [style.leftImage]:
            this.storeConfig.newsletterFormsPopupImagePosition === 'left' && this.storeConfig.newsletterFormsPopupImage,
        }}>
        {(this.storeConfig.newsletterFormsPopupTemplate !== 'pop-up' && !this.isMobile) || this.isMobile ? (
          <span
            onClick={this.closeNewsletter}
            aria-label={this.getTranslation(this.closeButtonLabel)}
            data-testid='newsletter-close'
            class={style.close}
          />
        ) : null}

        {(this.storeConfig.newsletterFormsPrePopupTitle &&
          this.storeConfig.newsletterFormsPopupImagePosition !== 'background') ||
        (this.storeConfig.newsletterFormsPopupImagePosition === 'background' && this.isMobile) ? (
          <header class={style.headerTitle}>
            <h3>{this.getTranslation(this.storeConfig.newsletterFormsPrePopupTitle)}</h3>
          </header>
        ) : null}

        <div class={style.newsletterContent}>
          {this.storeConfig.newsletterFormsPopupImage &&
          this.storeConfig.newsletterFormsPopupImagePosition !== 'left' &&
          this.newsletterStore.customerNewsletterStatus !== 'SUBSCRIBED' ? (
            <div class={style.image}>
              {/* TODO: temporary added img tag until newsletter image support cache path and webp format, then MainImage Component can be used */}
              <img
                src={this.extended.$config.images.baseUrl + image.src}
                srcset={
                  this.extended.$config.images.baseUrl +
                  image.src +
                  ' 1x, ' +
                  this.extended.$config.images.baseUrl +
                  image.src +
                  ' 2x'
                }
                alt={this.storeConfig.newsletterFormsPopupTitle}
                loading='lazy'
              />
              {/* <MainImage
                image={image}
                folder=''
                alt={this.storeConfig.newsletter_forms_popup_title}
                width={this.imageSizes.width}
                height={this.imageSizes.height}
                tabletWidth={this.imageSizes.width}
                tabletHeight={this.imageSizes.height}
                desktopWidth={this.imageSizes.width}
                desktopHeight={this.imageSizes.height}
                key='main-image'
              /> */}
            </div>
          ) : null}

          {this.newsletterStore.customerNewsletterStatus !== 'SUBSCRIBED' ? (
            <div class={style.newsletterBox}>
              {this.storeConfig.newsletterFormsPrePopupTitle &&
              this.storeConfig.newsletterFormsPopupImagePosition === 'background' &&
              !this.isMobile ? (
                <header class={style.headerTitle}>
                  <h3>{this.getTranslation(this.storeConfig.newsletterFormsPopupTitle)}</h3>
                </header>
              ) : null}

              {this.storeConfig.newsletterFormsPopupImage &&
              this.storeConfig.newsletterFormsPopupImagePosition === 'left' ? (
                <div class={style.image}>
                  {/* TODO: temporary added img tag until newsletter image support cache path and webp format, then MainImage Component can be used */}
                  <img
                    src={this.extended.$config.images.baseUrl + image.src}
                    srcset={
                      this.extended.$config.images.baseUrl +
                      image.src +
                      ' 1x, ' +
                      this.extended.$config.images.baseUrl +
                      image.src +
                      ' 2x'
                    }
                    alt={this.storeConfig.newsletterFormsPopupTitle}
                    loading='lazy'
                  />
                  {/* <MainImage
                    image={image}
                    alt={this.storeConfig.newsletter_forms_popup_title}
                    width={this.imageSizes.width}
                    height={this.imageSizes.height}
                    tabletWidth={this.imageSizes.width}
                    tabletHeight={this.imageSizes.height}
                    desktopWidth={this.imageSizes.width}
                    desktopHeight={this.imageSizes.height}
                    key='main-image'
                  /> */}
                </div>
              ) : null}

              <div class={style.content}>
                <InnerHTML contents={this.storeConfig.newsletterFormsPopupContent} key='pop-up-content' />

                <div class={style.newsletter}>
                  <FooterNewsletter
                    newsletterBtnLabel={this.buttonLabel}
                    inputLabel={this.newsletterInputLabel}
                    inputPlaceHolder={this.newsletterInputPlaceholder}
                    checkboxLabel={this.newsletterCheckboxPlaceholder}
                    key='newsletter-pop-up'
                  />
                </div>
              </div>
            </div>
          ) : (
            <div class={style.successMessage}>
              <i />
              <h3>{this.getTranslation(this.newsletterSuccessTitle)}</h3>
              {/* <p>{this.getTranslation(this.newsletterSuccessMessage)}</p> TODO: This should be enable with storeConfig configuration from BO */}
            </div>
          )}
        </div>
      </div>
    );
  }
}
