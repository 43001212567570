import { Component, BaseComponent } from '@zento-lib/components';
import { CmsBlock } from '@zento/modules/molecule/CmsBlock/CmsBlock';
import { GDPRStore } from 'theme/stores/gdpr/gdpr';
import { PopUpStore } from 'theme/stores/popUp/popUp';

import type { IPopUp } from './PopUp.d';
import style from './style.scss';

/**
 * Custom Pop Up
 *
 * Custom Pop Up component rendering static block content
 */
@Component({})
export class PopUp extends BaseComponent<IPopUp, unknown> {
  private gdprStore = new GDPRStore();
  private popUpStore = new PopUpStore();

  data() {
    return {
      open: false,
    };
  }

  /**
   * Close pop up on click
   */
  private closePopUp() {
    this.popUpStore.customPopUp = false;
    this.$data.open = false;
  }

  /**
   * Show pop up with time delay
   */
  showPopUp() {
    if (window.sessionStorage && sessionStorage.getItem('customPopUp') === null) {
      sessionStorage.setItem('customPopUp', 'yes');

      setTimeout(() => {
        this.$data.open = true;
      }, this.extended.$config.zento.theme.timeDelayPopUp);
    }
  }

  mounted() {
    this.showPopUp();
  }

  render() {
    return (
      <transition
        name={'slide-in-bottom'}
        enterActiveClass={style.slideInBottomEnterActive}
        leaveActiveClass={style.slideInBottomLeaveActive}>
        {this.$data.open ? (
          <div
            class={{
              [style.popUpWrapper]: true,
              [style.bottomPositionPopUp]: this.gdprStore.acceptCookies,
            }}>
            <span onClick={this.closePopUp} data-testid='pop-up-close' class={style.close} />
            <div class={style.popUpContent}>
              <div class={style.popUpBox}>
                <div class={style.content}>
                  <CmsBlock identifier='pop_up_drawer' />
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </transition>
    );
  }
}
