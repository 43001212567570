import { Component, BaseComponent, namespace } from '@zento-lib/components';
import { IVSFCompare, KEY as CompareKey } from 'theme/@types/vsf/stores/compare';
import type { IProductState } from 'theme/@types/vsf/stores/product';

import { MainImage } from '../../atom/MainImage/MainImage';
import { Button } from '../../atom/Button/Button';

import type { ICompareWidget } from './CompareWidget.d';
import style from './style.scss';

const compareStore = namespace<IVSFCompare>(CompareKey);

/**
 * Compare widget
 *
 * Renders a list of items currently added to compare
 */
@Component({})
export class CompareWidget extends BaseComponent<ICompareWidget, unknown> {
  private static T = {
    compareWidgetBtnLabel: 'compare_widget_btn_label',
  };

  @compareStore.State('items')
  items: IVSFCompare['state']['items'];

  @compareStore.Getter('isEmpty')
  isEmpty: boolean;

  @compareStore.Getter('isCompareLoaded')
  isCompareLoaded: IVSFCompare['state']['loaded'];

  mounted() {
    this.$store.dispatch('compare/load');
  }

  removeFromCompare(product: IProductState) {
    return this.isCompareLoaded ? this.$store.dispatch('compare/removeItem', product) : false;
  }

  thumbnailImage(product: IProductState) {
    return {
      src: product.image,
      loading: product.image,
    };
  }

  get imageSizes() {
    return this.extended.$config.zento.images.compareThumbnails;
  }

  render() {
    return !this.isEmpty && this.$route.path !== '/compare' ? (
      <div class={style.compareWidget} key='compare-widget' data-testId='compareWidget'>
        {this.items.length ? (
          <ul class={style.compareWidgetItems} key='compare-widget-list'>
            {this.items.map((product) => {
              return (
                <li key={product.id} class={style.compareWidgetItem}>
                  <MainImage
                    image={this.thumbnailImage(product)}
                    alt={product.name}
                    width={this.imageSizes.width}
                    height={this.imageSizes.height}
                    tabletWidth={this.imageSizes.tabletWidth}
                    tabletHeight={this.imageSizes.tabletHeight}
                    desktopWidth={this.imageSizes.desktopWidth}
                    desktopHeight={this.imageSizes.desktopHeight}
                  />
                  <span
                    onClick={() => this.removeFromCompare(product)}
                    data-testId='compare-widget-remove-item'
                    class={style.compareWidgetRemoveItem}
                    key='compare-widget-remove-item'
                  />
                </li>
              );
            })}
          </ul>
        ) : null}
        <Button
          handler={() => this.$router.push(this.extended.localizedRoute('/compare'))}
          styleType='primary'
          class={style.compareWidgetBtn}>
          {this.getTranslation({ id: CompareWidget.T.compareWidgetBtnLabel })}
        </Button>
      </div>
    ) : null;
  }
}
