import { Component, BaseComponent, Prop, namespace } from '@zento-lib/components';
import { AppContextStore, KEY as appKey } from 'theme/@types/zento/stores/applicationContext';

import type { IBackToTop } from './BackToTop.d';
import style from './style.scss';

const appContextStore = namespace<AppContextStore>(appKey);

@Component({})
export class BackToTop extends BaseComponent<IBackToTop, unknown> {
  @Prop({ type: Number, required: false, default: 600 })
  visibleoffset?: number;

  @Prop({ type: Number, required: false, default: 0 })
  visibleoffsetbottom?: number;

  @Prop({ type: String, required: false, default: '16px' })
  mobileRight?: string;

  @Prop({ type: String, required: false, default: 'auto' })
  mobileLeft?: string;

  @Prop({ type: String, required: false, default: '32px' })
  right?: string;

  @Prop({ type: String, required: false, default: 'auto' })
  left?: string;

  @Prop({ type: String, required: false, default: '16px' })
  mobileBottom?: string;

  @Prop({ type: String, required: false, default: '76px' })
  productMobileBottom?: string;

  @Prop({ type: String, required: false, default: '32px' })
  bottom?: string;

  @Prop({ type: Function, default: () => undefined })
  scrollFn?: (ev: Event) => void;

  @appContextStore.Getter('isMobile')
  private isMobile: boolean;

  data() {
    return {
      visible: false,
    };
  }

  beforeMount() {
    this.backToTop = this.backToTop.bind(this);
  }

  mounted() {
    if (typeof window !== 'undefined') {
      (window as any).smoothscroll = () => {
        const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;

        if (currentScroll > 0) {
          window.requestAnimationFrame((window as any).smoothscroll);
          window.scrollTo(0, Math.floor(currentScroll - currentScroll / 5));
        }
      };

      window.addEventListener('scroll', this.catchScroll);
    }
  }

  destroyed() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('scroll', this.catchScroll);
    }
  }

  render() {
    return (
      <transition
        name='fade-in-down'
        enterActiveClass={style.fadeInDownEnterActive}
        leaveActiveClass={style.fadeInDownLeaveActive}>
        <div
          onClick={this.backToTop}
          v-show={this.$data.visible}
          class={style.backToTop}
          style={{
            right: this.isMobile ? this.mobileRight : this.right,
            bottom: this.isMobile
              ? 'parentSku' in this.$route.params
                ? this.productMobileBottom
                : this.mobileBottom
              : this.bottom,
            left: this.isMobile ? this.mobileLeft : this.left,
          }}
        />
      </transition>
    );
  }

  private catchScroll() {
    const pastTopOffset = window.pageYOffset > this.visibleoffset;
    const pastBottomOffset =
      window.innerHeight + window.pageYOffset >= document.body.offsetHeight - this.visibleoffsetbottom;

    this.$data.visible = this.visibleoffsetbottom > 0 ? pastTopOffset && !pastBottomOffset : pastTopOffset;
    this.scrollFn(this as any);
  }

  private backToTop() {
    (window as any).smoothscroll();
  }
}
